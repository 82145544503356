<template>
  <div class="tasks-view">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'ProjectTasks' }">Tasks</router-link>
      </CBreadcrumbItem>

      <CBreadcrumbItem active>{{ taskName }}</CBreadcrumbItem>
    </teleport>

    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Index',
  inject: ['mountedComponent'],
  computed: {
    ...mapGetters({
      taskName: 'taskName',
    }),
  },
  async mounted() {
    await this.fetchTask({ task_id: this.$route.params.task_id })
  },
  methods: {
    ...mapActions({
      fetchTask: 'fetchTask',
    }),
  },
}
</script>

<style lang="scss" scoped>
.tasks-view {
}
</style>
